import { DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Stack, StackItem, TextField, Toggle } from '@fluentui/react';
import { Field, ValidationBar } from 'components';
import { IAddress } from 'components/Field/AddressField';
import states from 'components/Field/StateOptions';
import { useValidation } from 'hooks';
import useBenefitPlan from 'hooks/state/useBenefit';
import usePayers from 'hooks/state/usePayers';
import { IValidationConfig } from 'hooks/useValidation';

import dateOnly from 'utils/dateOnly';

export default function PayerPanel() {
    const {
        selectedPayer,
        cleanupSelectedPayer,
        payersData,
        updateSelectedPayerField,
        updatePayer,

        addPayer,
    } = usePayers();

    const { benefitPlanCategories } = useBenefitPlan();

    const isEditing = selectedPayer && payersData[selectedPayer.id] ? true : false;
    const isOpen = !!selectedPayer;
    const panelTitle = isOpen ? `${isEditing ? 'Manage' : 'Add'} Payer` : '';

    const validationConfig: IValidationConfig = [
        {
            fieldName: 'Name',
            validation: ['required'],
            value: selectedPayer?.name,
        },
    ];

    const [errors, onSubmit, cleanupErrors] = useValidation(validationConfig, onAddOrSave);

    function _onDismiss() {
        cleanupSelectedPayer();
        cleanupErrors();
    }

    function onAddOrSave() {
        if (selectedPayer) {
            cleanupErrors();
            if (isEditing) {
                updatePayer(selectedPayer);
            } else {
                addPayer(selectedPayer);
            }
        }
    }

    return (
        <Panel
            isOpen={isOpen}
            onDismiss={cleanupSelectedPayer}
            headerText={panelTitle}
            isFooterAtBottom
            type={PanelType.medium}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1 },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 5 }} grow>
                    <ValidationBar errors={errors} />
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <PrimaryButton text={isEditing ? 'Save' : 'Add'} onClick={onSubmit} />
                        <DefaultButton text="Cancel" onClick={_onDismiss} />
                    </Stack>
                </Stack>
            )}
        >
            <Stack tokens={{ childrenGap: 10 }} grow horizontal verticalAlign="end">
                <StackItem grow>
                    <TextField
                        label="Name"
                        required
                        value={selectedPayer?.name ?? ''}
                        onChange={(ev, value) => updateSelectedPayerField('name', value)}
                    />
                </StackItem>
                {isEditing && (
                    <Toggle
                        styles={{ root: { marginBottom: 3 } }}
                        onClick={() => updateSelectedPayerField('isDeleted', !selectedPayer?.isDeleted)}
                        label="Disabled"
                        inlineLabel
                        checked={selectedPayer?.isDeleted}
                    />
                )}
            </Stack>
            <TextField
                label="aEDI Payer Id"
                value={selectedPayer?.ediPayerId ?? ''}
                onChange={(ev, value) => updateSelectedPayerField('ediPayerId', value)}
            />
            <TextField
                label="Payer Id"
                value={selectedPayer?.payerId}
                onChange={(ev, value) => updateSelectedPayerField('payerId', value)}
            />
            <Stack tokens={{ childrenGap: 10 }} grow>
                <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                    <Stack.Item grow>
                        <TextField
                            label=" Line 1"
                            value={selectedPayer?.street1 ?? ''}
                            autoComplete="off"
                            onChange={(ev, value) => updateSelectedPayerField('street1', value)}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField
                            label=" Line 2"
                            value={selectedPayer?.street2 ?? ''}
                            autoComplete="off"
                            onChange={(ev, value) => updateSelectedPayerField('street2', value)}
                        />
                    </Stack.Item>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                    <Stack.Item grow>
                        <TextField
                            label="City"
                            placeholder=""
                            value={selectedPayer?.city ?? ''}
                            autoComplete="off"
                            onChange={(ev, value) => updateSelectedPayerField('city', value)}
                        />
                    </Stack.Item>
                    <Field.SearchCombo
                        label="State"
                        placeholder="(Select State)"
                        onChange={(e, option) => {
                            const value = option ? (option.key as string).toUpperCase() : '';
                            updateSelectedPayerField('state', value);
                        }}
                        selectedKey={selectedPayer?.state ? selectedPayer.state.toUpperCase() : undefined}
                        options={states}
                    />
                    <Field.ZipCode
                        label="Zip"
                        value={selectedPayer?.zip ?? ''}
                        autoComplete="off"
                        onChange={(ev, value) => updateSelectedPayerField('zip', value)}
                    />
                </Stack>
            </Stack>
            <Field.Phone
                label="Phone"
                value={selectedPayer?.phone}
                onChange={(ev, value) => updateSelectedPayerField('phone', value)}
            />
            <TextField
                label="Payer Type"
                value={selectedPayer?.payerType}
                onChange={(ev, value) => updateSelectedPayerField('payerType', value)}
            />
            <Dropdown
                label="Benefit Plan/Insurance"
                options={benefitPlanCategories}
                selectedKey={selectedPayer?.benefitPlanId as string}
                onChange={(ev, option) => updateSelectedPayerField('benefitPlanId', option?.key)}
            />
            <TextField
                label="Revision/Load Date"
                value={selectedPayer?.lastUpdated ? dateOnly(selectedPayer.lastUpdated) : ''}
                disabled
            />
            <Toggle
                label="Rollup same day encounters to one claim"
                inlineLabel
                checked={selectedPayer?.isEncounterRatePayer ?? false}
                onChange={(ev, checked) => updateSelectedPayerField('isEncounterRatePayer', !!checked)}
                offText="No"
                onText="Yes"
                styles={{ root: { margin: '10px 0' } }}
            />
        </Panel>
    );
}
