import { DefaultButton, ITheme, PrimaryButton, Stack, Text, memoizeFunction, mergeStyleSets, useTheme } from '@fluentui/react';
import { Section } from 'components';
import { useSelector } from 'hooks';
import useTenant from 'hooks/state/useTenant';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TaskType, selectUserTasksByTarget } from 'state/slices/task/selectors';
import { getTenantById, getTenantConfigView } from './state/tenants.actions';
import { ITenantConfigView } from './state/tenants.model';
import { selectTenantConfigView } from './state/tenants.selectors';

import { HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import { isDev } from 'api/platform.api';
import { getUserTasksByTarget } from 'state/slices/task/actions';
import { UserTaskTarget } from 'state/slices/task/model';
import DataConversionButton from './DataConversionButton';

const hubString = isDev ? `${process.env.REACT_APP_AppApiUri}/notifications` : `#{AppApiUri}#/notifications`;
const options: IHttpConnectionOptions = {};
const connection = new HubConnectionBuilder().withUrl(hubString, options).build();

function DataConversion() {
    const theme = useTheme();
    const { selectTenant } = useTenant();

    const userTasks = useSelector(selectUserTasksByTarget);

    const config = useSelector(selectTenantConfigView);
    const tenantId = selectTenant?.id;
    const dispatch = useDispatch();

    useEffect(() => {
        if (tenantId) dispatch(getUserTasksByTarget({ tenantId, target: UserTaskTarget.TenantSetup }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (tenantId) {
            setTimeout(() => {
                connection
                    .start()
                    .then(() => {
                        const { connectionId } = connection;

                        // Connect to tenant group
                        connection
                            .invoke('AddConnectionToGroupAsync', connectionId, 'OnboardingBeacon')
                            .then(() => console.log('Connected to tenant task...'))
                            .catch((err) => console.error(err.toString()));

                        // Listen to tenant onboarding message
                        connection.on('UserTask', (data) => {
                            console.log('UserTask fired');
                            dispatch(getUserTasksByTarget({ tenantId: tenantId, target: UserTaskTarget.TenantSetup }));
                            dispatch(getTenantById({ tenantId }));
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }, 150);
        }
        return () => {
            connection.stop();
        };
    }, [tenantId]);

    const arrayDataConversion = [
        config?.hasAllergiesChangeSet,
        config?.hasAppointmentChangeSet,
        config?.hasMedicationsChangeSet,
        config?.hasPatientsChangeSet,
        config?.hasPrescriptionChangeSet,
        config?.hasProblemsChangeSet,
        config?.hasProviderChangeSet,
    ];

    const startPatientDemographicLoadTask = userTasks.some((t) => t.type === TaskType.StartPatientDemographicLoad);
    const completeStopPatientDemographicTask = userTasks.some((t) => t.type === TaskType.CompletePatientDemographicLoad);
    const startDeltaPatientDemographicLoadTask = userTasks.some((t) => t.type === TaskType.StartDeltaPatientDemographicLoad);

    const completeDeltaPatientDemographicLoadTask = userTasks.some(
        (t) => t.type === TaskType.CompleteDeltaPatientDemographicLoad,
    );

    const isDataConversionFalse = arrayDataConversion.every((item) => item === false);

    useEffect(() => {
        if (selectTenant) dispatch(getTenantConfigView({ tenantId: selectTenant.id }));
    }, []);

    const { root, text } = getClasses(theme);

    return (
        <Section
            style={{
                height: '80vh',
                overflowY: 'auto',
            }}
            headingRightContent={
                <>
                    <DefaultButton text="Subscribe Changeset" />
                    <PrimaryButton text="Check Status" />
                </>
            }
            heading="DataConversion"
        >
            <Stack horizontalAlign="center">
                <Stack className={root}>
                    <Stack>
                        <Stack.Item>
                            <Text variant="mediumPlus">
                                {isDataConversionFalse
                                    ? 'Changesets OFF, safe to load patient data'
                                    : 'Changesets ON, not safe to load patient data'}{' '}
                            </Text>
                        </Stack.Item>
                        {config ? dataConversionList(config) : null}
                    </Stack>
                </Stack>
            </Stack>

            <Stack style={{ paddingTop: 30 }} tokens={{ childrenGap: 15 }}>
                {startPatientDemographicLoadTask || selectTenant?.startPatientDemographicsLoadOn ? (
                    <DataConversionButton
                        buttonText={!startPatientDemographicLoadTask ? 'Done' : 'Start Load'}
                        buttonDisabled={startPatientDemographicLoadTask}
                        verifiedName={selectTenant?.startPatientDemographicsLoadBy}
                        verifiedDate={selectTenant?.startPatientDemographicsLoadOn}
                        buttonOnClick={TaskType.StartPatientDemographicLoad}
                        label="started"
                    />
                ) : null}
                {completeStopPatientDemographicTask || selectTenant?.completePatientDemographicsLoadOn ? (
                    <DataConversionButton
                        buttonText={!completeStopPatientDemographicTask ? 'Done' : 'Completed Load'}
                        buttonDisabled={completeStopPatientDemographicTask}
                        verifiedName={selectTenant?.completePatientDemographicsLoadBy}
                        verifiedDate={selectTenant?.completePatientDemographicsLoadOn}
                        buttonOnClick={TaskType.CompletePatientDemographicLoad}
                        label="completed"
                    />
                ) : null}
                {selectTenant?.isNetNew ? (
                    <Stack tokens={{ childrenGap: 15 }}>
                        {startDeltaPatientDemographicLoadTask || selectTenant.startDeltaPatientDemographicsLoadOn ? (
                            <DataConversionButton
                                buttonText={!startDeltaPatientDemographicLoadTask ? 'Done' : 'Start Load'}
                                buttonDisabled={startDeltaPatientDemographicLoadTask}
                                verifiedName={selectTenant?.startDeltaPatientDemographicsLoadBy}
                                verifiedDate={selectTenant?.startDeltaPatientDemographicsLoadOn}
                                buttonOnClick={TaskType.StartDeltaPatientDemographicLoad}
                                label="started"
                                isDelta
                            />
                        ) : null}
                        {completeDeltaPatientDemographicLoadTask || selectTenant.completeDeltaPatientDemographicsLoadOn ? (
                            <DataConversionButton
                                buttonText={!completeDeltaPatientDemographicLoadTask ? 'Done' : 'Complete Load'}
                                buttonDisabled={completeDeltaPatientDemographicLoadTask}
                                verifiedName={selectTenant?.completeDeltaPatientDemographicsLoadBy}
                                verifiedDate={selectTenant?.completeDeltaPatientDemographicsLoadOn}
                                buttonOnClick={TaskType.CompleteDeltaPatientDemographicLoad}
                                label="completed"
                                isDelta
                            />
                        ) : null}
                    </Stack>
                ) : null}
            </Stack>
        </Section>
    );
}

export default DataConversion;

const classNames = {
    root: 'card',
    text: 'card_text',
    icon: 'card_icon',
};

const getClasses = memoizeFunction((theme: ITheme) =>
    mergeStyleSets({
        root: [
            classNames.root,
            {
                width: 400,
                height: 250,
                padding: 25,
                background: theme.palette.white,
                border: `1px solid ${theme.palette.neutralTertiary}`,

                borderRadius: 3,
                transition: 'box-shadow .15s',
                [`:hover`]: {
                    background: theme.palette.white,
                    boxShadow: theme.effects.elevation16,
                },
            },
        ],
        text: [
            classNames.text,
            {
                fontSize: 12,
                color: theme.semanticColors.link,
                [`.${classNames.root}:hover &`]: {
                    color: theme.semanticColors.linkHovered,
                    textDecoration: 'underline',
                },
            },
        ],
    }),
);

const hasChangeSetList: { displayName: string; key: keyof ITenantConfigView }[] = [
    {
        displayName: 'Provider',
        key: 'hasProviderChangeSet',
    },
    { displayName: 'Patients', key: 'hasPatientsChangeSet' },
    { displayName: 'Problems', key: 'hasProblemsChangeSet' },
    { displayName: 'Allergies', key: 'hasAllergiesChangeSet' },
    { displayName: 'Medications', key: 'hasMedicationsChangeSet' },
    { displayName: 'Prescriptions', key: 'hasPrescriptionChangeSet' },
    { displayName: 'Appointment', key: 'hasAppointmentChangeSet' },
];
const dataConversionList = (config: ITenantConfigView) => {
    const listItems = hasChangeSetList.map(({ displayName, key }) => {
        return (
            <Stack.Item key={key}>
                <Text variant="mediumPlus">
                    {displayName}: {config[key] ? 'Active' : 'Inactive'}
                </Text>
            </Stack.Item>
        );
    });
    return <Stack horizontalAlign="center">{listItems}</Stack>;
};
