import { DefaultButton, Stack, Text } from '@fluentui/react';
import { format } from 'date-fns';
import { useSelector } from 'hooks';
import useTenant from 'hooks/state/useTenant';
import useUsers from 'hooks/state/useUsers';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TaskType } from 'state/slices/task/selectors';
import { userAccount } from 'state/slices/user/user.slice';
import { getUsers } from 'state/slices/users.slice';
import { dataConversionOnClick } from './state/tenants.actions';

type DataConversionButtonProps = {
    buttonText?: string;
    buttonDisabled?: boolean;
    verifiedName?: string;
    verifiedDate?: string;
    label?: string;
    isDelta?: boolean;

    buttonOnClick?: string;
};

function DataConversionButton({
    buttonText,
    buttonDisabled,
    buttonOnClick,
    verifiedName,
    label,
    verifiedDate,
    isDelta,
}: DataConversionButtonProps) {
    const { selectUsersData } = useUsers();
    const { selectTenant } = useTenant();
    const account = useSelector(userAccount);
    const accountId = account?.identity.id;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUsers());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserStartName = () => {
        if (verifiedName) {
            const user = selectUsersData?.find((u) => u.identity?.id === verifiedName);
            if (user) return user.identity?.firstName + ' ' + user.identity?.lastName;
        }
        return '';
    };

    const _onClick = () => {
        if (selectTenant) {
            if (TaskType.StartPatientDemographicLoad === buttonOnClick)
                dispatch(
                    dataConversionOnClick({
                        tenants: {
                            ...selectTenant,
                            startPatientDemographicsLoadBy: accountId,
                            startPatientDemographicsLoadOn: new Date().toISOString(),
                        },
                        dataConversions: {
                            startPatientDemographicsLoad: true,
                        },
                    }),
                );

            if (TaskType.CompletePatientDemographicLoad === buttonOnClick)
                dispatch(
                    dataConversionOnClick({
                        tenants: {
                            ...selectTenant,
                            completePatientDemographicsLoadBy: accountId,
                            completePatientDemographicsLoadOn: new Date().toISOString(),
                        },
                        dataConversions: {
                            completePatientDemographicsLoad: true,
                        },
                    }),
                );

            if (TaskType.StartDeltaPatientDemographicLoad === buttonOnClick)
                dispatch(
                    dataConversionOnClick({
                        tenants: {
                            ...selectTenant,
                            startDeltaPatientDemographicsLoadBy: accountId,
                            startDeltaPatientDemographicsLoadOn: new Date().toISOString(),
                        },
                        dataConversions: {
                            startDeltaPatientDemographicsLoad: true,
                        },
                    }),
                );

            if (TaskType.CompleteDeltaPatientDemographicLoad === buttonOnClick)
                dispatch(
                    dataConversionOnClick({
                        tenants: {
                            ...selectTenant,
                            completePatientDemographicsLoadBy: accountId,
                            completeDeltaPatientDemographicsLoadBy: accountId,
                            completeDeltaPatientDemographicsLoadOn: new Date().toISOString(),
                        },
                        dataConversions: {
                            completeDeltaPatientDemographicsLoad: true,
                        },
                    }),
                );
        }
    };

    return (
        <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 5 }}>
            <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 10 }}>
                <DefaultButton
                    disabled={!buttonDisabled}
                    onClick={() => {
                        _onClick();
                    }}
                >
                    {buttonText}
                </DefaultButton>
                <Text variant="large">
                    I have {label} {isDelta ? 'delta' : ''} demographic load
                </Text>
            </Stack>
            {verifiedName ? (
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Text variant="large">
                        by {getUserStartName()} at {verifiedDate ? format(new Date(verifiedDate), 'Pp') : ''}
                    </Text>
                </Stack>
            ) : null}
        </Stack>
    );
}

export default DataConversionButton;
