import { SourceSystem } from 'api/models/source-system.model';
import ITenantProduct from 'api/models/tenantProduct.model';
import { IValidationError } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loadingStatus';
import ITenant, { ITenantConfigCopy, ITenantConfigCopySteps, ITenantConfigView, ITenantData, Services } from './tenants.model';
import { OnboardingState, onboardingInitialState } from '../Onboarding/state/onboarding.state';
import { Dictionary } from '@reduxjs/toolkit';
import { FinanicialClosingInitialState, FinanicialClosingState } from '../FinancialClosing/state/financialClosing.state';

export const initialState: TenantsState = {
    loading: LoadingStatus.Idle,
    loadingTenants: LoadingStatus.Idle,
    loadingTenantProducts: LoadingStatus.Idle,
    loadingSourceSystems: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    tenants: [],
    tenantProducts: [],
    validationErrors: [],
    isExpanded: true,
    showHistory: true,
    isAdding: false,
    isLiveCustomer: false,
    isUpcomingCustomer: false,

    selectedTenantPanelOpen: false,
    selectedTenantProductPanelOpen: false,
    servicePanelOpen: false,

    loadingTenantConfigView: LoadingStatus.Idle,
    loadingTenantConfigCopy: LoadingStatus.Idle,
    loadingConfigCopy: LoadingStatus.Idle,
    rerunModalOpen: false,

    ...onboardingInitialState,
    ...FinanicialClosingInitialState
};

export type TenantsState = {
    data?: ITenantData;
    tenants: ITenant[];

    sourceSystems?: Record<string, SourceSystem>;
    loadingSourceSystems: LoadingStatus;
    selectTenant?: ITenant;
    selectedTenantProduct?: ITenantProduct;
    selectedTenantHardCloseDateLoading: LoadingStatus;
    selectedTenantHardCloseDate?: string;
    selectedTenantService?: {
        service?: Services;
        id: string;
        startDate?: string;
        endDate?: string;
    };

    validationErrors: IValidationError[];
    isExpanded: boolean;
    loadingTenants?: LoadingStatus;
    loadingTenantProducts?: LoadingStatus;
    loadingEditTenant?: LoadingStatus;
    tenantProducts?: ITenantProduct[];
    errors?: any;
    userHasAccess?: boolean;
    saving?: LoadingStatus;
    loading: LoadingStatus;
    showHistory?: boolean;
    isLiveCustomer: boolean;
    isUpcomingCustomer: boolean;
    isAdding?: boolean;

    selectedTenantPanelOpen: boolean;
    selectedTenantProductPanelOpen: boolean;
    servicePanelOpen: boolean;

    loadingTenantConfigView: LoadingStatus;
    tenantConfigView?: ITenantConfigView;

    copyConfig?: ITenantConfigCopy;
    selectedTenantConfigCopy?: ITenantConfigCopy;
    selectedTenantConfigCopyStep?: Dictionary<ITenantConfigCopySteps>;
    configCopySteps?: ITenantConfigCopySteps;
    loadingConfigCopy: LoadingStatus;

    loadingTenantConfigCopy: LoadingStatus;

    rerunModalOpen: boolean;
} & OnboardingState & FinanicialClosingState;
