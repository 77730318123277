import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/loadingStatus';
import {
    addTenant,
    addTenantProduct,
    createTenantConfigCopy,
    editTenantProduct,
    getSourceSystems,
    getTenantById,
    getTenantConfigCopy,
    getTenantConfigView,
    getTenantProducts,
    getTenantProductsById,
    getTenants,
    rerunTenantConfigCopy,
    updateTenant,
    updateTenants,
} from './tenants.actions';
import tenantReducers from './tenants.reducers';
import { initialState } from './tenants.state';
import { onBoardingExtraReducers } from '../Onboarding/state/onboarding.reducers';
import financialClosingReducers, { financialClosingExtraReducers } from '../FinancialClosing/state/financialClosing.reducers';

/**
 * Gets all tenants defined in the system.
 */

const tenantsSlice = createSlice({
    name: 'tenants',
    initialState,
    reducers: {
        ...tenantReducers,
        ...financialClosingReducers
    },
    extraReducers: (builder) => {
        builder

            //get tenants
            .addCase(getTenants.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getTenants.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.errors = null;
                state.tenants = action.payload;
            })
            .addCase(getTenants.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            .addCase(getTenantById.pending, (state) => {
                state.loadingTenants = LoadingStatus.Pending;
            })
            .addCase(getTenantById.fulfilled, (state, action) => {
                state.loadingTenants = LoadingStatus.Completed;
                state.errors = null;
                state.selectTenant = action.payload;
            })
            .addCase(getTenantById.rejected, (state, action) => {
                state.loadingTenants = LoadingStatus.Failed;
                state.errors = action.payload;
            })

            //add tenant
            .addCase(addTenant.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(addTenant.fulfilled, (state, action) => {
                state.saving = LoadingStatus.Completed;
                state.errors = null;
                state.isAdding = false;
                state.tenants = [...(state.tenants ?? []), action.payload];
                state.selectedTenantPanelOpen = false;
                state.selectTenant = action.payload;
            })
            .addCase(addTenant.rejected, (state, action) => {
                state.saving = LoadingStatus.Failed;
                state.errors = action.payload;
            })

            //edit tenant
            .addCase(updateTenant.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(updateTenant.fulfilled, (state, action) => {
                state.saving = LoadingStatus.Completed;
                state.errors = null;

                state.selectedTenantPanelOpen = false;
                state.servicePanelOpen = false;
                state.tenants = [
                    ...state.tenants.map((tenant) => {
                        return tenant.id === action.payload.id ? action.payload : tenant;
                    }),
                ];
                state.selectTenant = action.payload;
            })
            .addCase(updateTenant.rejected, (state, action) => {
                state.saving = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            .addCase(updateTenants.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(updateTenants.fulfilled, (state, action) => {
                const tenants = action.payload;
                state.tenants = tenants;

                state.loading = LoadingStatus.Completed;
            })

            .addCase(updateTenants.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            //get tenant products
            .addCase(getTenantProducts.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getTenantProducts.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.errors = null;
                state.tenantProducts = [...action.payload];
            })
            .addCase(getTenantProducts.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.errors = action.payload;
            })

            //get tenant product
            .addCase(getTenantProductsById.pending, (state) => {
                state.loadingTenantProducts = LoadingStatus.Pending;
            })
            .addCase(getTenantProductsById.fulfilled, (state, action) => {
                state.loadingTenantProducts = LoadingStatus.Completed;
                state.errors = null;
                state.selectedTenantProduct = action.payload;
            })
            .addCase(getTenantProductsById.rejected, (state, action) => {
                state.loadingTenantProducts = LoadingStatus.Failed;
                state.errors = action.payload;
            })

            //add tenant product
            .addCase(addTenantProduct.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(addTenantProduct.fulfilled, (state, action) => {
                if (state.tenantProducts) {
                    state.tenantProducts = [...state.tenantProducts, action.payload];
                } else {
                    state.tenantProducts = [action.payload];
                }
                state.loading = LoadingStatus.Completed;
                state.errors = null;
                state.selectedTenantProductPanelOpen = false;
                state.isAdding = false;
                state.selectedTenantProduct = undefined;
            })
            .addCase(addTenantProduct.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.errors = action.payload;
            })

            //edit tenant product
            .addCase(editTenantProduct.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(editTenantProduct.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.saving = LoadingStatus.Completed;
                state.errors = null;
                state.selectedTenantProductPanelOpen = false;
                if (state.tenantProducts) {
                    state.tenantProducts = [
                        ...state.tenantProducts.map((tp) => {
                            return tp.id === action.payload.id ? action.payload : tp;
                        }),
                    ];
                }
            })
            .addCase(editTenantProduct.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.errors = action.payload;
            })
            .addCase(getSourceSystems.pending, (state) => {
                state.loadingSourceSystems = LoadingStatus.Pending;
            })
            .addCase(getSourceSystems.fulfilled, (state, action) => {
                state.loadingSourceSystems = LoadingStatus.Completed;
                state.sourceSystems = action.payload;
            })
            .addCase(getSourceSystems.rejected, (state) => {
                state.loadingSourceSystems = LoadingStatus.Failed;
            })
            .addCase(getTenantConfigView.pending, (state) => {
                state.loadingTenantConfigView = LoadingStatus.Pending;
            })
            .addCase(getTenantConfigView.fulfilled, (state, action) => {
                state.loadingTenantConfigView = LoadingStatus.Completed;
                state.tenantConfigView = action.payload;
            })
            .addCase(getTenantConfigView.rejected, (state) => {
                state.loadingTenantConfigView = LoadingStatus.Failed;
            })
            .addCase(getTenantConfigCopy.pending, (state) => {
                state.loadingTenantConfigCopy = LoadingStatus.Pending;
            })
            .addCase(getTenantConfigCopy.fulfilled, (state, action) => {
                state.loadingTenantConfigCopy = LoadingStatus.Completed;
                state.selectedTenantConfigCopy = action.payload;
            })
            .addCase(getTenantConfigCopy.rejected, (state) => {
                state.loadingTenantConfigCopy = LoadingStatus.Failed;
            })
            .addCase(createTenantConfigCopy.pending, (state) => {
                state.loadingConfigCopy = LoadingStatus.Pending;
            })
            .addCase(createTenantConfigCopy.fulfilled, (state) => {
                state.loadingConfigCopy = LoadingStatus.Completed;
            })
            .addCase(rerunTenantConfigCopy.pending, (state) => {
                state.loadingConfigCopy = LoadingStatus.Pending;
            })
            .addCase(rerunTenantConfigCopy.fulfilled, (state) => {
                state.loadingConfigCopy = LoadingStatus.Completed;
            })
        onBoardingExtraReducers(builder);
        financialClosingExtraReducers(builder);
    },
});

export const {
    cleanupSelectedTenants,
    toggleShowTenantsHistory,
    toggleShowLiveCustomer,
    toggleShowUpcomingCustomer,
    cleanupShowCustomer,
    addNewTenant,
    updateSelectedTenantsProp,
    updateTenantsProp,
    updateSelectedBillingTenantsProp,
    updateSelectedAddressTenantsProp,
    isRerunModalOpen,

    updateTenantSettings,
    setTenantPanelIsOpen,
    setTenantProductPanelIsOpen,
    addNewTenantProduct,
    updateSelectedTenantsProductsProp,
    updateSelectedPayToAddressTenantsProp,
    updateSelectedStatementAddressTenantsProp,
    cleanupSelectedProductTenants,

    setEditTenantError,
    toggleExpanded,
    updateTenantsConfigProp,

    //Service
    setServicePanelIsOpen,
    setSelectedServiceDataProp,
    setSelectedServiceAndOpenPanel,
    cleanupSelectedService,
    addProductServiceSubscription,
    setProductServiceSubscriptionProp,
    removeProductServiceSubscription,

    //Tenant Hard Close Date
    cleanupTenantHardCloseThroughDate,
    cleanupTenantHardCloseHistory,
    setHardCloseModalOpen,
    setHardCloseHistoryData,
    setHardCloseThroughDate,
    cleanupHardCloseModal,
    cleanupTenantHardCloseMessageBar

} = tenantsSlice.actions;

export default tenantsSlice.reducer;
