import { Route, Switch } from 'react-router-dom';
import { EditTenantMenuItem } from './TenantPivot';
import About from './About';
import Billing from './Billing';
import Onboarding from './Onboarding/Onboarding';
import Subscriptions from './Subscriptions';
import DataConversion from './DataConversion';
import CopyConfig from './ConfigCopy';
import usePermissions, { Permission } from 'hooks/usePermissions';
import { AccessDenied } from 'components/AccessDenied';
import FinancialClosing from './FinancialClosing/FinancialClosing';

function TenantRoutes() {
    const [hasAbout] = usePermissions([Permission.TenantAbout]);
    const [hasBilling] = usePermissions([Permission.TenantBilling]);
    const [hasIntegration] = usePermissions([Permission.TenantIntegration]);
    const [hasSubscriptions] = usePermissions([Permission.TenantSubscriptions]);

    return (
        <Switch>
            <Route path={`/tenants/:tenantId/`} exact component={hasAbout ? About : AccessDenied} />
            <Route path={`/tenants/:tenantId/${EditTenantMenuItem.About}`} exact component={hasAbout ? About : AccessDenied} />
            <Route path={`/tenants/:tenantId/${EditTenantMenuItem.Billing}`} component={hasBilling ? Billing : AccessDenied} />
            <Route
                path={`/tenants/:tenantId/${EditTenantMenuItem.OnBoarding}`}
                component={hasIntegration ? Onboarding : AccessDenied}
            />
            <Route
                path={`/tenants/:tenantId/${EditTenantMenuItem.Subscriptions}`}
                component={hasSubscriptions ? Subscriptions : AccessDenied}
            />
            <Route
                path={`/tenants/:tenantId/${EditTenantMenuItem.DataConversion}`}
                component={hasIntegration ? DataConversion : AccessDenied}
            />
            <Route
                path={`/tenants/:tenantId/${EditTenantMenuItem.ConfigCopy}`}
                component={hasIntegration ? CopyConfig : AccessDenied}
            />
            <Route
                path={`/tenants/:tenantId/${EditTenantMenuItem.FinancialClosing}`}
                component={FinancialClosing}
            />
        </Switch>
    );
}

export default TenantRoutes;
