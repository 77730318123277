import { ContextualMenu, IContextualMenuItem, IContextualMenuProps } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { EditTenantMenuItem } from './TenantPivot';
import usePermissions, { Permission } from 'hooks/usePermissions';

type Props = {
    tenants: any;
} & Partial<IContextualMenuProps>;

type IContextualMenuItemExtended = IContextualMenuItem & {
    permissions?: Permission[];
};

export default function TenantsBarContextMenu({ tenants, ...props }: Props) {
    const { push } = useHistory();

    const _onChangePivot = (menuItem: EditTenantMenuItem) => {
        push(`/tenants/${tenants.keyString}/${menuItem}`);
    };

    const menuItems: IContextualMenuItemExtended[] = [
        {
            key: 'about',
            text: 'About',
            onClick: () => _onChangePivot(EditTenantMenuItem.About),
            permissions: [Permission.TenantAbout],
        },
        {
            key: 'subscription',
            text: 'Subscription',
            onClick: () => {
                _onChangePivot(EditTenantMenuItem.Subscriptions);
            },
            permissions: [Permission.TenantSubscriptions],
        },
        {
            key: 'onboarding',
            text: 'Integration',
            onClick: () => _onChangePivot(EditTenantMenuItem.OnBoarding),
            permissions: [Permission.TenantIntegration],
        },
        {
            key: 'billing',
            text: 'Billing',
            onClick: () => _onChangePivot(EditTenantMenuItem.Billing),
            permissions: [Permission.TenantBilling],
        },
        {
            key: 'financialClosing',
            text: 'Financial Closing',
            onClick: () => _onChangePivot(EditTenantMenuItem.FinancialClosing),
            permissions: [Permission.TenantBilling],
        },
        {
            key: 'dataConversion',
            text: 'Data Conversion',
            onClick: () => _onChangePivot(EditTenantMenuItem.DataConversion),
            permissions: [Permission.TenantIntegration],
        },
        {
            key: 'configCopy',
            text: 'Config Copy',
            onClick: () => _onChangePivot(EditTenantMenuItem.ConfigCopy),
            permissions: [Permission.TenantIntegration],
        },
    ];

    return <ContextualMenu {...props} items={menuItems} />;
}
