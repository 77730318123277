import { Dictionary, createAsyncThunk } from '@reduxjs/toolkit';
import { SourceSystem } from 'api/models/source-system.model';
import ITenantProduct from 'api/models/tenantProduct.model';
import axios from 'axios';
import { push } from 'connected-react-router';
import { filter } from 'lodash';
import ErrorTypes from 'state/errorTypes';
import { AppDispatch, AppThunk, RootState } from 'state/store';
import ITenant, {
    ITenantConfigCopy,
    ITenantConfigCopySteps,
    ITenantConfigSetups,
    ITenantConfigView,
} from 'views/pages/Tenants/state/tenants.model';
import tenantsApi from './tenants.api';

/**
 * Gets all tenants defined in the system.
 */
export const getTenants = createAsyncThunk<ITenant[], undefined, { rejectValue: string }>(
    'tenants/getTenants',
    async (_, { rejectWithValue }) => {
        try {
            const { data: tenantsList } = await tenantsApi.getTenants();

            return tenantsList;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const getTenantById = createAsyncThunk<ITenant, { tenantId: string }, { rejectValue: string }>(
    'tenants/getTenantById',
    async ({ tenantId }, { rejectWithValue }) => {
        try {
            const { data: Tenant } = await tenantsApi.getTenant(tenantId);
            return Tenant;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

/**
 * Creates a new tenant.
 */
export const addTenant = createAsyncThunk<ITenant, { tenant: ITenant }, { rejectValue: string; dispatch: AppDispatch }>(
    'tenants/addTenant',
    async ({ tenant }, { dispatch, rejectWithValue }) => {
        try {
            const { data: result } = await tenantsApi.addTenant(tenant);
            dispatch(push(`/tenants/${result.id}`));
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

/**
 * Edits an existing tenant.
 */
export const updateTenant = createAsyncThunk<ITenant, { tenant: ITenant }, { rejectValue: string }>(
    'tenants/updateTenant',
    async ({ tenant }, { rejectWithValue }) => {
        try {
            const { data: result } = await tenantsApi.updateTenant(tenant);
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const updateTenants = createAsyncThunk<ITenant[], { tenants: ITenant[] }, { rejectValue: string }>(
    'tenants/updateTenants',
    async ({ tenants }, { rejectWithValue }) => {
        try {
            const requests = tenants.map((tenant) => tenantsApi.updateTenant(tenant));
            const responses = await axios.all(requests);
            return responses.map((r) => r.data);
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

/**
 * Gets all product subscriptions for a given tenant.
 */
export const getTenantProducts = createAsyncThunk<ITenantProduct[], { tenantId: string }, { rejectValue: string }>(
    'tenants/getTenantProducts',
    async ({ tenantId }, { rejectWithValue }) => {
        try {
            const { data: tenantProducts } = await tenantsApi.getTenantProducts(tenantId);
            const result = filter(tenantProducts, (product) => product !== undefined);
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);
export const getTenantProductsById = createAsyncThunk<
    ITenantProduct,
    { tenantId: string; productId: string },
    { rejectValue: string }
>('tenants/getTenantProductsById', async ({ tenantId, productId }, { rejectWithValue }) => {
    try {
        const { data: tenantProduct } = await tenantsApi.getTenantProductsByID(tenantId, productId);

        return tenantProduct;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

/**
 * Creates a new tenant product subscription.
 */
export const addTenantProduct = createAsyncThunk<
    ITenantProduct,
    { tenantId: string; product: ITenantProduct },
    { rejectValue: string }
>('tenants/addTenantProduct', async ({ tenantId, product }, { rejectWithValue }) => {
    try {
        const { data: result } = await tenantsApi.addTenantProduct(tenantId, product);
        return result;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

/**
 * Edits an existing tenant product subscription.
 */
export const editTenantProduct = createAsyncThunk<
    ITenantProduct,
    { tenantId: string; product: ITenantProduct },
    { rejectValue: string }
>('tenants/editTenantProduct', async ({ tenantId, product }, { rejectWithValue }) => {
    try {
        const { data: result } = await tenantsApi.updateTenantProduct(tenantId, product);
        return result;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const getTenantConfigView = createAsyncThunk<ITenantConfigView, { tenantId: string }, { rejectValue: string }>(
    'tenants/getTenantConfigView',
    async ({ tenantId }, { rejectWithValue }) => {
        try {
            const { data: result } = await tenantsApi.getTenantConfigView(tenantId);
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const updatedTenantSetups = createAsyncThunk<ITenantConfigSetups, { model: ITenantConfigSetups }, { rejectValue: string }>(
    'tenants/updatedTenantSetups',
    async ({ model }, { rejectWithValue }) => {
        try {
            const { data: result } = await tenantsApi.updatedTenantSetups(model);
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const getSourceSystems = createAsyncThunk<Record<string, SourceSystem>, undefined, { rejectValue: string }>(
    'tenants/getSourceSystems',
    async (_, { rejectWithValue }) => {
        try {
            const { data: result } = await tenantsApi.getSourceSystems();
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const fetchTenantData =
    (tenantId: string): AppThunk<void> =>
        (dispatch) => {
            dispatch(getTenantById({ tenantId }));
            dispatch(getTenantProducts({ tenantId }));
        };

type VerifyConfigurationResponse = { tenant: ITenant; dataConversion: ITenantConfigSetups };
export const dataConversionOnClick = createAsyncThunk<
    VerifyConfigurationResponse,
    {
        tenants: ITenant;
        dataConversions: ITenantConfigSetups;
    },
    { state: RootState; dispatch: AppDispatch; rejectValue: string }
>('tenants/test', async ({ tenants, dataConversions }, { getState, rejectWithValue }) => {
    const selectTenant = getState().tenants.selectTenant;

    if (selectTenant) {
        try {
            // Save tenant before running onboarding
            const { data: tenant } = await tenantsApi.updateTenant({
                ...selectTenant,

                ...tenants,
            });

            // Save tenant setup before switching to tenant setup page
            const { data: dataConversion } = await tenantsApi.updatedTenantSetups({
                ...dataConversions,
                tenantId: selectTenant?.id,
                tenantName: selectTenant?.displayName,
                isNetNew: selectTenant?.isNetNew,
            });

            return { tenant, dataConversion };
        } catch (err: any) {
            return rejectWithValue(err.toString());
        }
    }
    return rejectWithValue('No tenant selected');
});

export const getTenantConfigCopy = createAsyncThunk<ITenantConfigCopy, { tenantId: string }, { rejectValue: string }>(
    'tenants/getTenantConfigCopy',
    async ({ tenantId }, { rejectWithValue }) => {
        try {
            const { data: result } = await tenantsApi.getTenantConfigCopy(tenantId);
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const createTenantConfigCopy = createAsyncThunk<ITenantConfigCopy, { model: ITenantConfigCopy }, { rejectValue: string }>(
    'tenants/createTenantConfigCopy',
    async ({ model }, { rejectWithValue }) => {
        try {
            const { data: result } = await tenantsApi.createTenantConfigCopy(model);
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

export const rerunTenantConfigCopy = createAsyncThunk<
    ITenantConfigCopy,
    { model: { tenantId: string; tenantConfigCopyStepIds: string[] } },
    { rejectValue: string }
>('tenants/rerunTenantConfigCopy', async ({ model }, { rejectWithValue }) => {
    try {
        const { data: result } = await tenantsApi.rerunTenantConfigCopy(model);
        return result;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const getTenantConfigCopyStepsById = createAsyncThunk<
    Dictionary<ITenantConfigCopySteps>,
    { tenantId: string },
    { rejectValue: string }
>('tenants/getTenantConfigCopySteps', async ({ tenantId }, { rejectWithValue }) => {
    try {
        const { data: result } = await tenantsApi.getTenantConfigCopyStepsById(tenantId);
        return result;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const getTenantConfigCopySteps = createAsyncThunk<Dictionary<ITenantConfigCopySteps>, undefined, { rejectValue: string }>(
    'tenants/getTenantConfigCopySteps',
    async (_, { rejectWithValue }) => {
        try {
            const { data: result } = await tenantsApi.getTenantConfigCopySteps();
            return result;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

