import React from 'react';
import { getTheme } from '@fluentui/react';

interface IProps {
    title?: string | JSX.Element;
    style?: React.CSSProperties;
    childStyle?: React.CSSProperties;
    className?: string;
}

const theme = getTheme();

const Card: React.FC<IProps> = (props) => {
    return (
        <div
            style={{
                backgroundColor: theme.palette.white,
                borderRadius: 2,
                display: 'flex',
                ...props.style,
            }}
            className={`ms-depth-4 ${props.className ? props.className : ''}`}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    padding: 20,
                }}
            >
                {props.title ? <h3 style={{ marginBottom: 10 }}>{props.title}</h3> : null}
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        ...props.childStyle,
                    }}
                >
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default Card;
